import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import SpeakerSingle from "../../src/components/speaker-single";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SpeakerSingle name="Rebeca Gregson Tovar" profession="Madre migrada, psicóloga social especialista en participación y acción comunitaria. La Tregua e IPECAL (Barcelona)" presentation="Migración, racismo y maternidad: construyendo nuevos horizontes de sentido." youtubeid="JFxwuiWDmC4" hasspeach="true" skipTo={{
      h: 0,
      m: 35,
      s: 43
    }} mdxType="SpeakerSingle" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      